module.exports={
  "_args": [
    [
      "crossfilter2@1.4.0-alpha.06",
      "/home/ar/data/repos/projects/pattrn.co/pattrn"
    ]
  ],
  "_from": "crossfilter2@1.4.0-alpha.6",
  "_id": "crossfilter2@1.4.0-alpha.6",
  "_inCache": true,
  "_installable": true,
  "_location": "/crossfilter2",
  "_nodeVersion": "5.10.1",
  "_npmOperationalInternal": {
    "host": "packages-12-west.internal.npmjs.com",
    "tmp": "tmp/crossfilter2-1.4.0-alpha.6.tgz_1463519571786_0.49269671249203384"
  },
  "_npmUser": {
    "email": "esjewett@gmail.com",
    "name": "esjewett"
  },
  "_npmVersion": "3.8.3",
  "_phantomChildren": {},
  "_requested": {
    "name": "crossfilter2",
    "raw": "crossfilter2@1.4.0-alpha.06",
    "rawSpec": "1.4.0-alpha.06",
    "scope": null,
    "spec": "1.4.0-alpha.6",
    "type": "version"
  },
  "_requiredBy": [
    "/"
  ],
  "_resolved": "https://registry.npmjs.org/crossfilter2/-/crossfilter2-1.4.0-alpha.6.tgz",
  "_shasum": "f0197c6fab2d6a583b51254bfc6357093f80521b",
  "_shrinkwrap": null,
  "_spec": "crossfilter2@1.4.0-alpha.06",
  "_where": "/home/ar/data/repos/projects/pattrn.co/pattrn",
  "author": {
    "name": "Mike Bostock",
    "url": "http://bost.ocks.org/mike"
  },
  "bugs": {
    "url": "https://github.com/crossfilter/crossfilter/issues"
  },
  "contributors": [
    {
      "name": "Jason Davies",
      "url": "http://www.jasondavies.com/"
    }
  ],
  "dependencies": {
    "lodash.result": "^4.4.0"
  },
  "description": "Fast multidimensional filtering for coordinated views.",
  "devDependencies": {
    "browserify": "^13.0.0",
    "d3": "3.5",
    "package-json-versionify": "1.0.2",
    "uglify-js": "2.4.0",
    "vows": "0.7.0"
  },
  "directories": {},
  "dist": {
    "shasum": "f0197c6fab2d6a583b51254bfc6357093f80521b",
    "tarball": "https://registry.npmjs.org/crossfilter2/-/crossfilter2-1.4.0-alpha.6.tgz"
  },
  "files": [
    "src",
    "index.js",
    "crossfilter.js",
    "crossfilter.min.js"
  ],
  "gitHead": "509a96798f5153a58d1b6cae5fb3e7893129ce7c",
  "homepage": "http://crossfilter.github.com/crossfilter/",
  "keywords": [
    "analytics",
    "visualization",
    "crossfilter"
  ],
  "main": "./index.js",
  "maintainers": [
    {
      "email": "esjewett@gmail.com",
      "name": "esjewett"
    },
    {
      "email": "gordon@woodhull.com",
      "name": "gordonwoodhull"
    },
    {
      "email": "tannerlinsley@gmail.com",
      "name": "tannerlinsley"
    }
  ],
  "name": "crossfilter2",
  "optionalDependencies": {},
  "readme": "ERROR: No README data found!",
  "repository": {
    "type": "git",
    "url": "git+ssh://git@github.com/crossfilter/crossfilter.git"
  },
  "scripts": {
    "benchmark": "node test/benchmark.js",
    "build": "browserify index.js -t package-json-versionify --standalone crossfilter -o crossfilter.js && uglifyjs --compress --mangle --screw-ie8 crossfilter.js -o crossfilter.min.js",
    "clean": "rm -f crossfilter.js crossfilter.min.js",
    "test": "vows --verbose"
  },
  "version": "1.4.0-alpha.6"
}
