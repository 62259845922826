export function hello_devs_hello() {
  console.log(
String.raw`
 _    _      _ _         _____
| |  | |    | | |       |  __ \
| |__| | ___| | | ___   | |  | | _____   _____
|  __  |/ _ \ | |/ _ \  | |  | |/ _ \ \ / / __|
| |  | |  __/ | | (_) | | |__| |  __/\ V /\__ \
|_|  |_|\___|_|_|\___/  |_____/ \___| \_/ |___/

`);
}
