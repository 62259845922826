module.exports = {
  "has_own_property": "var pug_has_own_property=Object.prototype.hasOwnProperty;",
  "merge": "function pug_merge(r,e){if(1===arguments.length){for(var t=r[0],a=1;a<r.length;a++)t=pug_merge(t,r[a]);return t}for(var g in e)if(\"class\"===g){var l=r[g]||[];r[g]=(Array.isArray(l)?l:[l]).concat(e[g]||[])}else if(\"style\"===g){var l=pug_style(r[g]),n=pug_style(e[g]);r[g]=l+(l&&n&&\";\")+n}else r[g]=e[g];return r}",
  "classes_array": "function pug_classes_array(r,a){for(var s,e=\"\",u=\"\",c=Array.isArray(a),g=0;g<r.length;g++)s=pug_classes(r[g]),s&&(c&&a[g]&&(s=pug_escape(s)),e=e+u+s,u=\" \");return e}",
  "classes_object": "function pug_classes_object(r){var a=\"\",n=\"\";for(var o in r)o&&r[o]&&pug_has_own_property.call(r,o)&&(a=a+n+o,n=\" \");return a}",
  "classes": "function pug_classes(s,r){return Array.isArray(s)?pug_classes_array(s,r):s&&\"object\"==typeof s?pug_classes_object(s):s||\"\"}",
  "style": "function pug_style(r){if(!r)return\"\";if(\"object\"==typeof r){var e=\"\",t=\"\";for(var n in r)pug_has_own_property.call(r,n)&&(e=e+t+n+\":\"+r[n],t=\";\");return e}return r=\"\"+r,\";\"===r[r.length-1]?r.slice(0,-1):r}",
  "attr": "function pug_attr(t,e,n,r){if(e===!1||null==e||!e&&(\"class\"===t||\"style\"===t))return\"\";if(e===!0)return\" \"+(r?t:t+'=\"'+t+'\"');if(\"function\"==typeof e.toISOString)e=e.toISOString();else if(\"string\"!=typeof e&&(e=JSON.stringify(e),!n&&-1!==e.indexOf('\"')))return\" \"+t+\"='\"+e.replace(/'/g,\"&#39;\")+\"'\";return n&&(e=pug_escape(e)),\" \"+t+'=\"'+e+'\"'}",
  "attrs": "function pug_attrs(t,r){var a=\"\";for(var s in t)if(pug_has_own_property.call(t,s)){var u=t[s];if(\"class\"===s){u=pug_classes(u),a=pug_attr(s,u,!1,r)+a;continue}\"style\"===s&&(u=pug_style(u)),a+=pug_attr(s,u,!1,r)}return a}",
  "match_html": "var pug_match_html=/[\"&<>]/;",
  "escape": "function pug_escape(e){var a=\"\"+e,t=pug_match_html.exec(a);if(!t)return e;var r,c,n,s=\"\";for(r=t.index,c=0;r<a.length;r++){switch(a.charCodeAt(r)){case 34:n=\"&quot;\";break;case 38:n=\"&amp;\";break;case 60:n=\"&lt;\";break;case 62:n=\"&gt;\";break;default:continue}c!==r&&(s+=a.substring(c,r)),c=r+1,s+=n}return c!==r?s+a.substring(c,r):s}",
  "rethrow": "function pug_rethrow(n,e,r,t){if(!(n instanceof Error))throw n;if(!(\"undefined\"==typeof window&&e||t))throw n.message+=\" on line \"+r,n;try{t=t||require(\"fs\").readFileSync(e,\"utf8\")}catch(i){pug_rethrow(n,null,r)}var a=3,o=t.split(\"\\n\"),h=Math.max(r-a,0),s=Math.min(o.length,r+a),a=o.slice(h,s).map(function(n,e){var t=e+h+1;return(t==r?\"  > \":\"    \")+t+\"| \"+n}).join(\"\\n\");throw n.path=e,n.message=(e||\"Pug\")+\":\"+r+\"\\n\"+a+\"\\n\\n\"+n.message,n}"
}
